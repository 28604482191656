import React from 'react';
import Router from 'next/router';
import NProgress from 'nprogress';
import Head from 'next/head';
import { AppProps } from 'next/app';
import { init } from '../lib/sentry';
import { ProjectContextProvider } from '../context/ProjectContext';
import { ToastContextProvider } from '../context/ToastContext';
import ContextProvider from '../context/Provider';
import 'tippy.js/dist/tippy.css';
import '../styles.css';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  init();
}

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});

Router.events.on('routeChangeComplete', () => {
  NProgress.done();
});

Router.events.on('routeChangeError', () => {
  NProgress.done();
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
function MyApp({ Component, pageProps, err }: AppProps) {
  return (
    <>
      <Head>
        <title>Bloop | Organización de eventos</title>
        <meta
          name="google-site-verification"
          content="qHzwHhdioCZC6jz_nvAzPUViSElTp_KdSiFUTBGgP2k"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#030819" />
        <meta name="theme-color" content="#5bbad5" />
        <meta name="title" content="Bloop | Organización de eventos" />
        <meta
          name="description"
          content="A través de aliados estratégico, facilitamos la planificación de tu próximo evento. Compara, aprueba y disfruta con Bloop!"
        />
        <meta
          name="keywords"
          content="Escenario, Fotografía, Video, Música, Display, Licor, Alcohol, Coctelera, Catering, Comida, Pasapalos, Buffet, Estación de Comida, Postres, Dulces, Toldo, Carpa, Iluminación, Decoración, Seguridad, Valet Parking, Protocolo, Cotillo, Dj, Banda, Artista, Transporte, Mobiliario, Evento, Event Planner, Wedding Planner, Events, Organización de eventos, Foto de Bodas, Fotógrafo, Fotógrafo de bodas, Boda, Quince aos, Cumpleaños, Aniversario, Bar Mitzvah, Boda Civil, Primera comunión, Evento Corporativo, Corporativo, Concierto, Conferencia, Graduación, Bautizo"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.probloop.com/" />
        <meta
          property="og:title"
          content="Bloop | Organización de eventos"
          key="ogtitle"
        />
        <meta
          property="og:description"
          content="A través de aliados estratégico, facilitamos la planificación de tu próximo evento. Compara, aprueba y disfruta con Bloop!"
          key="ogdesc"
        />
        <meta property="og:image" content="/avatar-bloop.jpg" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.probloop.com/" />
        <meta
          property="twitter:title"
          content="Bloop | Organización de eventos"
        />
        <meta
          property="twitter:description"
          content="A través de aliados estratégico, facilitamos la planificación de tu próximo evento. Compara, aprueba y disfruta con Bloop!"
        />
        <meta property="twitter:image" content="/avatar-bloop.jpg" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
        <script
          dangerouslySetInnerHTML={{
            __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '1468916866986196');
                fbq('track', 'PageView');`,
          }}
        />
        <noscript>
          <img
            height="1"
            width="1"
            style={{ display: 'none' }}
            alt="pixel"
            src="https://www.facebook.com/tr?id=1468916866986196&ev=PageView&noscript=1"
          />
        </noscript>
      </Head>
      <ToastContextProvider>
        <ContextProvider>
          <ProjectContextProvider>
            <Component {...pageProps} err={err} />
          </ProjectContextProvider>
        </ContextProvider>
      </ToastContextProvider>
    </>
  );
}

export default MyApp;
