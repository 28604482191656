import React from 'react';
import { useRouter } from 'next/router';
import { User } from '../models';
import AppContext from './auth-context';
import firebase from '../lib/firebase/client';

interface ContextProviderProps {
  children: React.ReactNode;
}

export default function ContextProvider({ children }: ContextProviderProps) {
  const [user, setUser] = React.useState<User>(null);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(function authCheck() {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (fbUser) => {
      try {
        setLoading(true);
        if (fbUser) {
          const ref = (
            await firebase
              .firestore()
              .collection('/users')
              .doc(`/${fbUser.uid}`)
              .get()
          ).data();
          setUser((ref as unknown) as User);
        } else {
          setUser(null);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    });
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  return (
    <AppContext.Provider value={{ user, setUser }}>
      {loading ? (
        <div className="w-screen h-screen bg-primary-500" />
      ) : (
        children
      )}
    </AppContext.Provider>
  );
}
